import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutUs = () => {
  return (
    <Layout>
      <SEO
        title='Acerca de Nosotros'
        description='Somos una empresa con una vasta trayectoria, que ofrece las mejores soluciones que van de la mano con la tecnología, llevamos trabajando varios años, y nuestros resultados han sido extraordinarios'
      />
      {/* Begin Title */}
      <section className='bg-gray-900'>
        <div className='container bg-grey-lightest mx-auto shadow rounded pb-4 bg-cover'>
          <div className='mt-2 p-4 border-b border-grey-light  text-left'>
            <h2 className='xs:text-lg sm:text-3xl text-4xl font-thin text-white'>
              Acerca de Nosotros
            </h2>
          </div>
        </div>
      </section>
      {/* Begin About */}
      <section className='mt-4 mb-4'>
        <div className='container mx-auto px-4'>
          <div className='block md:flex justify-between md:-mx-2'>
            <p className='text-justify'>
              Somos una empresa con una vasta trayectoria, que ofrece las
              mejores soluciones que van de la mano con la tecnología, llevamos
              trabajando varios años, y nuestros resultados han sido
              extraordinarios. Contamos con profesionales perfectamente
              calificados que garantizan la calidad y eficiencia de nuestro
              trabajo. Dentro de nuestro portafolio ofrecemos: Diseño y
              Desarrollo de Páginas y Aplicaciones Web, Marketing Digital, entre
              otros. Nuestro servicio es garantizado, pues, el proceso que se
              realiza con cada cliente es personalizado, lo que nos convierte en
              una de las mejores alternativas al momento de optar por un
              proveedor de servicios informáticos
            </p>
          </div>
        </div>
      </section>
      {/* Begin Title */}
      <section>
        <div className='container mx-auto  pb-4'>
          <div className='mt-2 p-4 border-b border-grey-light  text-left'>
            <h2 className='xs:text-lg sm:text-3xl text-4xl font-thin text-gray-900'>
              Misión
            </h2>
          </div>
        </div>
      </section>
      {/* Begin About */}
      <section className='mt-4 mb-4'>
        <div className='container mx-auto px-4'>
          <div className='block md:flex justify-between md:-mx-2'>
            <p className='text-justify'>
              Somos un equipo de profesionales que oferta servicios
              especializados en posicionamiento SEO, Google Adwords, Marketing
              Online diseño y desarrollo web, desarrollo multimedia, diseño de
              marca, diseño y desarrollo de aplicaciones móviles Apps, creación
              de aplicaciones, instalación de servidores, infraestructura y
              mucho más.
            </p>
          </div>
        </div>
      </section>
      {/* Begin Title */}
      <section>
        <div className='container mx-auto  pb-4'>
          <div className='mt-2 p-4 border-b border-grey-light  text-left'>
            <h2 className='xs:text-lg sm:text-3xl text-4xl font-thin text-gray-900'>
              Visión
            </h2>
          </div>
        </div>
      </section>
      {/* Begin About */}
      <section className='mt-4 mb-4'>
        <div className='container mx-auto px-4'>
          <div className='block md:flex justify-between md:-mx-2'>
            <p className='text-justify'>
              Ser una marca líder a nivel nacional e internacional en soluciones
              digitales a la vanguardia de la tecnología para su negocio o
              empresa.
            </p>
          </div>
        </div>
      </section>
      {/* Begin About */}
      <section className='mt-4 mb-4'>
        <div className='container mx-auto px-4'>
          <div className='block md:flex justify-between md:-mx-2'>
            <p className='text-justify'>
              Somos un equipo de profesionales que oferta servicios
              especializados en posicionamiento SEO, Google Adwords, Marketing
              Online diseño y desarrollo web, desarrollo multimedia, diseño de
              marca, diseño y desarrollo de aplicaciones móviles Apps, creación
              de aplicaciones, instalación de servidores, infraestructura y
              mucho más.
            </p>
          </div>
        </div>
      </section>
      {/* Begin Title */}
      <section>
        <div className='container mx-auto  pb-4'>
          <div className='mt-2 p-4 border-b border-grey-light  text-left'>
            <h2 className='xs:text-lg sm:text-3xl text-4xl font-thin text-gray-900'>
              ¿Cómo nació WP?
            </h2>
          </div>
        </div>
      </section>
      {/* Begin About */}
      <section className='mt-4 mb-4'>
        <div className='container mx-auto px-4'>
          <div className='block md:flex justify-between md:-mx-2'>
            <p className='text-justify'>
              Todas las empresas tienen una historia que contar , algo que
              conecte con la marca y haga clic con sus clientes. Es así que yo,
              Wilson Pérez un emprendedor de 26 años de edad, orgullosamente
              ecuatoriano, apasionado por la tecnología, destacado en mis
              habilidades informáticas y aprendiendo al ritmo que avanza la
              ciencia les voy a contar brevemente como nació la marca WP. Con la
              expectativa de ser el pionero en el desarrollo de aplicaciones y
              herramientas informáticas al servicio de cualquier negocio o
              empresa, la idea fue concebida el 29 de junio de 2008, que
              posteriormente maduró y ahora está tomando fuerza como WP que
              sencillamente responde a mi nombre y apellido.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutUs
